import "./App.css";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import React, { Suspense, lazy, useState, useEffect } from "react";
import { IKContext } from "imagekitio-react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getFirestore, doc, collection } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import { Home } from "./pages/Home";
import { useDocumentData } from "react-firebase-hooks/firestore";
// import useUserLocation from "./useUserLocation";

const Dashboard = React.lazy(() => import("./CMS/pages/Dashboard"));
const Editor = React.lazy(() => import("./CMS/pages/Editor"));
const Post = React.lazy(() => import("./CMS/pages/Post"));
const Blog = React.lazy(() => import("./CMS/pages/DashboardTabs/Blog"));
const More = React.lazy(() => import("./CMS/pages/More"));

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCgBnQZRwdkD7Jabm1yp_TlXQ1Z4nvnw4Q",
  authDomain: "review-be194.firebaseapp.com",
  projectId: "review-be194",
  storageBucket: "review-be194.appspot.com",
  messagingSenderId: "741563763031",
  appId: "1:741563763031:web:1ccb3501355599c5e71612",
  measurementId: "G-KP4YHB24K1",
  databaseURL: "https://simple-format.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth();
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

function App(props) {
  const [user, setUser] = useState();

  // Rerout to welcome if no importances yet (and repair)

  // const { position, locatiomeEror, reaskPermission } = useUserLocation();

  // Load metadata
  const [metadata, loading, error] = useDocumentData(
    doc(collection(db, "metadata"), "metadata")
  );

  return (
    <MantineProvider>
      <Notifications />
      <IKContext urlEndpoint="https://ik.imagekit.io/cis">
        {" "}
        {/* <HeatMap functions={functions} db={db}> */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {" "}
            {/* <Route
              path="/dashboard/:tab?"
              element={
                <>
                  {" "}
                  <NavBar></NavBar>
                  <Dashboard
                    metadata={metadata}
                    db={db}
                    user={user}
                    signInSuccessUrl="dashboard"
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                    isValidMail={true}
                    buttonStyles="bg-sky-500 hover:bg-sky-600 text-white"
                  />
                </>
              }
            /> */}
            {/* <Route
              path="/editpost/:name"
              element={
                <Editor
                  metadata={metadata}
                  db={db}
                  user={user}
                  isValidMail={true}
                  firebase={app}
                  functions={functions}
                  auth={auth}
                  // For old functions which expect imgs not in imgs/ folder
                  // replaceBucketImgs={"unimix"}
                  analytics={analytics}
                  app={app}
                  maxPostsInSection={20}
                />
              }
            /> */}
            {/* <Route
              path="/meer"
              element={
                <>
                  <NavBar></NavBar>
                  <More
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    functions={functions}
                    auth={auth}
                    analytics={analytics}
                  />
                </>
              }
            /> */}
            {/* <Route
              path="/blog"
              element={
                <>
                  <NavBar overlay={true} />
                  <Blog
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    auth={auth}
                    analytics={analytics}
                    buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                    hideLearnMore
                    secondaryTitleStyles="text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500"
                  />
                </>
              }
            /> */}
            {/* <Route
              path="/posts/:name"
              element={
                <>
                  <NavBar overlay={true} className="-mb-12" />
                  <Post
                    metadata={metadata}
                    db={db}
                    user={user}
                    firebase={app}
                    auth={auth}
                    analytics={analytics}
                    buttonStyles="p-1 px-3 rounded-lg bg-gradient-to-br from-purple-600 to-yellow-500 text-white text-md font-bold hover:px-5 transition-all duration-200"
                    hideLearnMore
                    secondaryTitleStyles="text-transparent bg-clip-text bg-gradient-to-br from-green-500 to-yellow-200"
                  />
                </>
              }
            /> */}
            <Route
              path="/"
              element={
                <Home
                  metadata={metadata}
                  db={db}
                  user={user}
                  firebase={app}
                  // askLocation={reaskPermission}
                  auth={auth}
                  analytics={analytics}
                  // position={position}
                />
              }
            />
          </Routes>
        </Suspense>
      </IKContext>
    </MantineProvider>
  );
}

export default App;
