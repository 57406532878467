import { Button } from "@mantine/core";
import React from "react";
import { BiSolidContact } from "react-icons/bi";
import { NavBar } from "../NavBar";
import { Link } from "react-router-dom";

export const Home = (props) => {
  return (
    <div className="w-screen">
      <NavBar overlay={true} />
      <div className="w-full h-screen -mt-20 bg-gradient-to-r from-teal-200 to-teal-300">
        <div className="flex mx-auto flex-col items-center justify-center p-16 h-full">
          {" "}
          <img
            src={process.env.PUBLIC_URL + "IMG.png"}
            className="flex max-w-4xl drop-shadow-lg  -mr-12"
          />
          <div className="absolute mx-auto self-center bg-white rounded-xl p-16 flex flex-col justify-center items-center gap-8 shadow-lg max-w-xl">
            {" "}
            <h1 className="text-8xl title text-slate-800">Schattigservies</h1>
            <p className="text-slate-700">
              Ben je op zoek naar schattig servies voor een speciale
              gelegenheid? Dan ben je hier aan het juiste adres! Wij bieden een
              breed assortiment aan van het allerleukste en schattigste servies,
              perfect voor elke gelegenheid. Mix en Match de schattigste
              verzameling serviezen met een geschiedenis.
            </p>
            <Link to="mailto:SB@accettte.eu">
              <Button
                color="blue"
                className="bg-gradient-to-r from-teal-200 to-teal-300 hover:from-teal-400 hover:to-teal-500 transition-all duration-200 text-white font-bold"
                size="lg"
                leftIcon={<BiSolidContact />}
              >
                Contact
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-slate-50">
        {" "}
        <div className="mx-auto flex flex-col justify-center items-center py-16 max-w-2xl gap-8">
          <h2 className="text-6xl title text-slate-800">
            Waarom Schattigservies?
          </h2>
          <p className="text-slate-700">
            Unieke Collectie: Ontdek ons breed scala aan schattig servies, van
            vintage theekopjes tot moderne borden met prachtige designs.
          </p>
          <p className="text-slate-700">
            Kwaliteit Gegarandeerd: Al ons servies is van hoge kwaliteit en
            wordt grondig gereinigd voor en na elke verhuur.
          </p>
          <p className="text-slate-700">
            Flexibele Huuropties: Of je nu servies nodig hebt voor een eenmalig
            evenement of voor een langere periode, wij hebben flexibele
            huurpakketten die aan jouw behoeften voldoen.
          </p>
          <p className="text-slate-700">
            Duurzaamheid: Door servies te huren, draag je bij aan een duurzamere
            wereld en verminder je afval.
          </p>
          <h2 className="text-6xl title text-slate-800">
            Perfect voor elke gelegenheid!
          </h2>
          <p className="text-slate-700">
            Of je nu een bruiloft, verjaardagsfeest, babyshower, high tea of een
            ander speciaal evenement organiseert, met ons schattige servies maak
            je indruk op je gasten en creëer je onvergetelijke momenten.
          </p>
          <p className="text-slate-700">
            Zet vandaag nog de eerste stap naar een sfeervolle gelegenheid en
            neem contact met ons op voor de beste servies verhuur opties!
          </p>
        </div>
      </div>
      <div className="bg-gradient-to-r from-teal-200 to-teal-300 h-32 w-full flex flex-col justify-center items-center font-bold text-white">
        <p> © Schattigservies.nl</p>
        <Link
          className="hover:text-sky-500 underline"
          to="https://www.garzen.nl"
        >
          Design: Garzen.nl
        </Link>
      </div>
    </div>
  );
};
