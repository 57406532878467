import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import * as Scroll from "react-scroll";
import {
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  BrandLinkedin,
  BrandWhatsapp,
  Mail,
  Phone,
  Search,
} from "tabler-icons-react";
import { BeautyButton } from "./CMS/buttons/BeautyButton";
import { MultiButton } from "./CMS/buttons/MultiButton";
import { Burger, Kbd } from "@mantine/core";
import { openSpotlight } from "@mantine/spotlight";
import { useViewportSize } from "@mantine/hooks";

export const NavBar = (props) => {
  const { width, height } = useViewportSize();
  const [opened, setOpened] = useState(false);
  const title = opened ? "Close navigation" : "Open navigation";

  const handleKeyPress = (event) => {
    // check if the Shift key is pressed
    if (
      (event.metaKey === true || event.ctrlKey === true) &&
      event.key === "k"
    ) {
      console.log(`Key pressed: ${event.key} ${props.search}`);

      props.setSearch(!props.search);
    }
  };

  // useEffect(() => {
  //   // attach the event listener
  //   document.addEventListener("keydown", handleKeyPress);

  //   // remove the event listener
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, [handleKeyPress]);

  return (
    <div
      className={` text-gray-900 w-full z-50 overflow-x-clip top-0  ${
        props.freefloat && width > 640 && "-mb-28"
      } ${props.overlay && "md:text-white md:opacity-90"} ${props.className}`}
    >
      <Burger
        opened={opened}
        onClick={() => setOpened((o) => !o)}
        title={title}
        size={32}
        // color={"gray"}
        className="z-50 rounded-xl backdrop-blur text-lg absolute top-4 md:hidden left-4 "
      />

      <div
        className={`absolute z-40 top-0 bg-white md:bg-transparent w-full h-screen md:h-auto  justify-center flex flex-col  md:relative  ${
          !opened && "-translate-y-full  md:translate-y-0"
        } transition-all duration-500 `}
      >
        <nav
          className={`items-center md:mx-8 md:mt-4 md:flex z-40  h-full md:h-auto  md:flex-row justify-center md:justify-between  px-2 font-bold text-xl `}
        >
          {width > 640 && (
            <Link
              to="/"
              className={`flex flex-col justify-center cursor-pointer hover:text-gray-700  text-2xl hover:border-b hover:-translate-y-1 transition-all duration-200 ${
                props.overlay && width > 640
                  ? "text-white"
                  : "text-transparent bg-clip-text bg-gradient-to-br from-green-600 to-yellow-500"
              }
            ${props.freefloat && width > 640 && "text-slate-100"}
            `}
              // Readd:
              duration={500}
            >
              <p className="flex gap-2 items-center title font-light text-6xl">
                <img
                  className="h-16 w-16 drop-shadow"
                  src={process.env.PUBLIC_URL + "/servies-icon.png"}
                  alt="servies website logo"
                />{" "}
                Schattig Servies .nl
              </p>
              {/* <img src={logo} className="w-20  hidden md:block"></img> */}
            </Link>
          )}
          <div
            className={`flex items-center h-4/5 flex-col md:h-auto md:flex-row gap-8 justify-around md:my-0 my-20  ${
              props.freefloat && width > 640 && "text-white"
            } ${props.overlay && "md:text-white md:opacity-90"}`}
          >
            {/* <Link className="md:my-auto mx-auto" to="/" duration={500}>
              <p
                className={`cursor-pointer ${
                  props.overlay && "md:text-white md:opacity-90"
                } hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 md:hidden`}
                onClick={() => (window.location.href = "/")}

                // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                Home
              </p>
            </Link> */}
            {/* <Link
              onClick={() => {
                props.setOpenSettings(!props.openSettings);
                setOpened(false);
              }}
              className="md:my-auto mx-auto"
              to="/"
              duration={500}
            >
              <p
                className={`cursor-pointer ${props.overlay && "md:text-white md:opacity-90"} hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 md:hidden`}
                // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                {props.openSettings ? "Close " : "Open "} Settings
              </p>
            </Link> */}
            {/* <Link
              // onClick={() => logEvent(props.analytics, "About Click")}
              className={`md:my-auto mx-auto ${
                props.overlay && "md:text-white md:opacity-90"
              }`}
              to="/alles"
              duration={500}
            >
              <p
                className={`cursor-pointer ${
                  props.overlay && "md:text-white md:opacity-90"
                }  hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200`}
                // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                ALLE UITJES
              </p>
            </Link>
            <Link
              // onClick={() => logEvent(props.analytics, "About Click")}
              className={`md:my-auto mx-auto ${
                props.overlay && "md:text-white md:opacity-90"
              }`}
              to="/dichtbij"
              duration={500}
            >
              <p
                className={`cursor-pointer ${
                  props.overlay && "md:text-white md:opacity-90"
                }  hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200`}
                // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                IN DE BUURT{" "}
              </p>
            </Link>
            <Link
              // onClick={() =>
              //   logEvent(props.analytics, "NavBar Click", { title: "Blog" })
              // }
              className="md:my-auto mx-auto"
              to="/blog"
              duration={500}
            >
              <p
                className={`cursor-pointer ${
                  props.overlay && "md:text-white md:opacity-90"
                } hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200`}
                // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                BLOG
              </p>
            </Link> */}

            {/* <Link
              onClick={() => logEvent(props.analytics, "Specialty Click")}
              className="md:my-auto mx-auto"
              to="/home/specialties"
              duration={500}
            >
              <p className={`cursor-pointer ${props.overlay && "md:text-white md:opacity-90"} hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 `}
              // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                Specialties
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "Commodity Click")}
              className="md:my-auto mx-auto"
              to="/home/commodities"
              duration={500}
            >
              <p className={`cursor-pointer ${props.overlay && "md:text-white md:opacity-90"} hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 `}
              // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                Commodities
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "Services Click")}
              className="md:my-auto mx-auto"
              to="/home/services"
              duration={500}
            >
              <p className={`cursor-pointer ${props.overlay && "md:text-white md:opacity-90"} hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 `}
              // Readd: text-transparent bg-clip-text bg-gradient-to-br from-purple-600 to-yellow-500
              >
                Services
              </p>
            </Link> */}
            {/* <MultiButton
              buttons={[
                {
                  onClick: () => {
                    logEvent(props.analytics, "App Click");
                    window.open("https://wa.me/5511982222105");
                  },
                  text: <BrandWhatsapp></BrandWhatsapp>,
                  css: "",
                },
                {
                  onClick: () => {
                    logEvent(props.analytics, "Mail Click");
                    window.open("mailto:saulo@unimixbrasil.com.br");
                  },
                  text: <Mail></Mail>,
                  css: "",
                },
                {
                  onClick: () => logEvent(props.analytics, "LinkedIn Click"),
                  text: <BrandLinkedin></BrandLinkedin>,
                  css: "",
                },
              ]}
            ></MultiButton> */}
            {/* <Link to="/home">
              <BeautyButton
                onClick={() => {
                  props.setSearch(true);
                }}
                hideAnim
                bgStyling={
                  "backdrop-blur-2xl hover:backdrop-blur-sm bg-transparent hover:bg-transparent border-2 transition-all duration-500 group text-gray-500"
                }
                text={
                  <>
                    <Search className="group-hover:rotate-45 transition-all duration-500"></Search>
                    <span className="block md:hidden mx-2">Search...</span>
                    {window.navigator.platform === "MacIntel" ? (
                      <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                        ⌘
                      </Kbd>
                    ) : (
                      <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                        ctrl
                      </Kbd>
                    )}
                    +
                    <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                      k
                    </Kbd>
                  </>
                }
              ></BeautyButton>
            </Link> */}
          </div>
        </nav>
      </div>
    </div>
  );
};
